.light .accordion-list-item.selected {
  background-color: #cccccc !important;
}
.light .arrow-icon {
  filter: invert(100%);
}

.accordion-list {
  height: 100%;
}
.accordion-list-item {
  height: 70px;
  transition: 0.3s;
}
.accordion-list-item.selected {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 20px;
  z-index: 1000;
  width: 100%;
  padding: 10px;
  background-color: #383838 !important;
}
@media (min-width: 1024px) {
  .accordion-list-item.selected {
    padding: 0;
    border-radius: 0;
    position: initial;
    background: none;
    height: calc(100% - var(--accordion-count) * 70px);
  }
}
@media (min-width: 1440px) {
  .accordion-list-item.selected {
    height: calc(100% - var(--accordion-count) * 25px);
  }
}
.accordion-list-item:not(:first-child) {
  border-top: 1px #ffffff80 solid;
}
.accordion-list-item-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .accordion-list-item-header .arrow-icon.hidden {
    display: none;
  }
}
.accordion-list-item-body {
  max-height: calc(100% - var(--accordion-count) * 30px);
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.accordion-list-item-body.hidden {
  height: 0;
  visibility: hidden;
  max-height: 0;
  min-height: 0;
}
.accordion-list-item-body__item {
  display: flex;
  gap: 10px;
}
.accordion-list-item-body__item-text {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff80;
}