.delivery-page {
  padding: 10px 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}
@media (min-width: 768px) {
  .delivery-page {
    padding: 20px 15vw;
  }
}

.ant-modal-confirm-content {
  margin-left: 0 !important;
}