.add-address-page {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: 10px;
  height: 100%;
}
@media (min-width: 768px) {
  .add-address-page {
    width: 50%;
    height: 100%;
  }
}
.add-address-page-map {
  width: 100%;
  height: calc(100vh - 100px);
}
.add-address-page-current-address {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.add-address-page-current-address__metadata {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-self: center;
  font-weight: bold;
}
@media (min-width: 768px) {
  .add-address-page-current-address__metadata {
    align-self: center;
  }
}
.add-address-page-current-address__metadata .references {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 225px;
}
.add-address-page-current-address .address-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 768px) {
  .add-address-page-current-address .address-container {
    padding: 0 10px;
  }
}
.add-address-page-current-address .address-container .search-bar-container-input input::-moz-placeholder {
  text-transform: capitalize;
}
.add-address-page-current-address .address-container .search-bar-container-input input::placeholder {
  text-transform: capitalize;
}
.add-address-page-current-address .address-container .current-location-btn {
  align-self: flex-start;
  margin-left: -10px;
}
.add-address-page-content {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-radius: 4px;
}
.add-address-page__save {
  left: 10px;
  right: 10px;
  align-self: flex-end;
  background: #7c9c46;
  color: #ffffff;
  right: 0;
  bottom: 0;
  border: none;
  border-radius: 10px;
  width: calc(100% - 20px);
  position: absolute;
  bottom: 50px;
}
@media (min-width: 768px) {
  .add-address-page__save {
    position: relative;
    bottom: 0;
  }
}
.add-address-page__save:hover {
  background: #7c9c4680;
  color: #ffffff;
  filter: brightness(1.2);
}
@media (min-width: 768px) {
  .add-address-page__save {
    width: -moz-fit-content;
    width: fit-content;
  }
}
.add-address-page .breadcrumb {
  margin-left: 20px;
}