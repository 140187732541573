@import '../preference/preference.css';
.preferences-list {
  padding: 0 0 15px 0;
  width: 100%;
  overflow-x: scroll;
}
.preferences-list-container {
  display: flex;
  gap: 10px;
  padding: 0 15px;
  width: -moz-fit-content;
  width: fit-content;
}