.ingredient-list {
  height: 200px;
  overflow-y: scroll;
  margin-top: 20px;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ingredient-list-item {
  background-color: #ffffff;
  padding: 5px;
}
.ingredient-list-item__checkbox .ant-checkbox-inner {
  background-color: #8d3251;
  border-color: #8d3251;
}
.ingredient-list-show-more-btn {
  width: 100%;
  text-align: center;
}
.ingredient-list-show-more-btn button {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border: none;
  font-weight: bold;
  border-radius: 5px;
}
.ingredient-list-show-more-btn button:hover {
  color: #8d3251;
}