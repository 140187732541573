.address-modal .ant-modal-content {
  border-radius: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.address-modal-map {
  height: 60vh;
}
.address-modal__title {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5019607843);
  font-weight: bold;
  font-size: 18px;
}
.address-modal .ant-modal-confirm-btns .ant-btn {
  border-radius: 10px;
  border: none;
}
.address-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #8d3251;
}
.address-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  filter: brightness(1.2);
  color: #ffffff;
}