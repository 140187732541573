@import '../preferences-list/preferences-list.css';
@import '../ingredient-list/ingredient-list.css';
.preferences-modal .ant-modal {
  border-radius: 10px;
}
.preferences-modal .ant-modal-content {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.preferences-modal .ant-modal-header {
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  border-radius: 10px 10px 0 0;
}
.preferences-modal .ant-modal-close-icon {
  filter: drop-shadow(1px 0 0 #fff);
}
.preferences-modal-title {
  color: #ffffff;
  width: 100%;
}
.preferences-modal-cancel-btn {
  border: none;
  border-radius: 10px;
}
.preferences-modal-cancel-btn:hover {
  color: #8d3251;
}
.preferences-modal-confirm-btn {
  background: #8d3251;
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 10px;
}
.preferences-modal-confirm-btn:hover {
  color: #ffffff;
  background: #8d3251;
  filter: brightness(1.2);
}