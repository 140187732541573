.story-frame {
  display: flex;
  flex-direction: column;
}
.story-frame-img {
  display: flex;
  flex-direction: column;
  align-content: center;
  border-radius: 100%;
  box-shadow: -2px 0px 0px 5.5px rgba(141, 50, 81, 0.2509803922), 2px 0px 0px 5.5px rgba(141, 50, 81, 0.2509803922);
  margin-bottom: 5px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-self: center;
  cursor: pointer;
}
.story-frame-caption {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  width: -moz-max-content;
  width: max-content;
}

.stories-list {
  display: flex;
  gap: 40px;
  margin: 10px 5%;
  padding: 20px 10px 10px 10px;
  border-top: 1px rgba(255, 255, 255, 0.253) solid;
  border-bottom: 1px rgba(255, 255, 255, 0.253) solid;
  overflow-x: auto;
}
.stories-list-close {
  display: none;
}
.stories-list-btns {
  display: none;
}
.stories-list.fullscreen {
  position: absolute;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.97);
  height: 110vh;
  width: 100vw;
  margin: -104px 0 0 0;
  align-content: center;
  padding-top: 50px;
}
@media (min-width: 768px) {
  .stories-list.fullscreen {
    width: calc(100vw - 200px);
    margin: -66px 0 0 0;
  }
  .stories-list.fullscreen .stories-list-close {
    top: 25px !important;
  }
}
.stories-list.fullscreen .stories-list-close {
  display: flex;
  color: #ffffff;
  font-size: 20px;
  position: absolute;
  right: 25px;
  top: 60px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 0 10px;
  border-radius: 100%;
  z-index: 1000;
  cursor: pointer;
}
.stories-list.fullscreen .stories-list-btns {
  display: flex;
  height: 0;
  width: 100%;
  position: absolute;
  justify-content: space-between;
  align-self: center;
  padding-left: 20px;
  padding-right: 20px;
}
.stories-list.fullscreen .stories-list-btns .stories-list-btn {
  height: 50px;
  cursor: pointer;
}
.stories-list.fullscreen .story-frame {
  margin-right: auto;
  margin-left: auto;
  align-self: center;
  display: flex;
  flex-direction: column;
}
.stories-list.fullscreen .story-frame-img {
  width: 210px;
  height: 220px;
  border-radius: 20px;
}
@media (min-width: 768px) {
  .stories-list.fullscreen .story-frame-img {
    width: 530px;
    height: 550px;
  }
}
.stories-list.fullscreen .story-frame-caption {
  align-self: center;
  font-size: 30px;
  font-weight: bold;
}
.stories-list.fullscreen .story-frame:not(.selected) {
  display: none;
}
.stories-list:empty {
  display: flex;
  gap: 30px;
}
.stories-list:empty::after {
  content: "";
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-bottom: 15px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
.stories-list:empty::before {
  content: "";
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-bottom: 15px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 14%);
  }
  100% {
    background-color: hsl(0, 0%, 20%);
  }
}