.arrow-icon.right {
  transform: rotate(180deg);
}
.arrow-icon.top-left {
  transform: rotate(45deg);
}
.arrow-icon.bottom-right {
  transform: rotate(225deg);
}
.arrow-icon.dark {
  filter: invert(100%);
}

.step {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff80;
}