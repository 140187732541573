.profile-info {
  border-radius: 10px;
  border: none;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.profile-info__title {
  display: flex;
  justify-content: space-between;
}
.profile-info__body {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
@media (min-width: 768px) {
  .profile-info__body .data {
    padding: 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
  }
  .profile-info__body .data .ant-input {
    border-radius: 10px;
  }
}
.profile-info__body-save {
  background: #8d3251;
  border: none;
  cursor: pointer;
  width: -moz-fit-content;
  width: fit-content;
  align-self: flex-end;
  border-radius: 15px;
}
.profile-info__body-save:hover, .profile-info__body-save:focus {
  background: #8d3251;
  filter: brightness(1.2);
}
.profile-info__body.closed {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media (min-width: 768px) {
  .profile-info__body.closed {
    flex-direction: row;
  }
}