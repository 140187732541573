.order-list-item {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex !important;
  padding: 20px;
  height: 130px;
  border-bottom: 1px #5E5E5E solid !important;
  cursor: pointer;
  gap: 10px;
}
@media (min-width: 768px) {
  .order-list-item {
    height: 74px;
  }
}
.order-list-item .latest-header {
  display: none;
}
.order-list-item.latest {
  height: 145px;
}
@media (min-width: 768px) {
  .order-list-item.latest {
    height: 89px;
  }
}
.order-list-item.latest .latest-header {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #8d3251;
  width: 100%;
  text-align: center;
  border-radius: 8px 8px 0 0;
  color: #ffffff;
  font-weight: bold;
}
.order-list-item__icon {
  width: 40px;
  position: relative;
}
.order-list-item__icon .in-progress {
  position: absolute;
  bottom: 0;
  right: 5px;
  transform: translateY(5px);
}
.order-list-item__icon .canceled {
  position: absolute;
  position: absolute;
  color: white;
  border: 1px white solid;
  border-radius: 100%;
  padding: 0 5px;
  background-color: rgb(124, 0, 0);
  font-size: 10px;
  font-weight: bold;
  bottom: 0;
  right: 5px;
  transform: translateY(5px);
}
.order-list-item__icon .ready {
  position: absolute;
  color: white;
  border: 1px white solid;
  border-radius: 100%;
  padding: 0 5px;
  background-color: #92b851;
  font-size: 10px;
  font-weight: bold;
  bottom: 0;
  right: 5px;
  transform: translateY(5px);
}
.order-list-item__metadata {
  display: flex;
  gap: 5px;
  flex: 20;
  flex-direction: column;
}
@media (min-width: 768px) {
  .order-list-item__metadata {
    flex-direction: row;
    gap: 1vw;
  }
}
.order-list-item__metadata-status {
  color: #ffffff;
  text-align: initial;
  font-size: 18px;
  font-weight: bold;
}
@media (min-width: 768px) {
  .order-list-item__metadata-status {
    text-align: center;
    font-size: 16px;
  }
}
.order-list-item__metadata-date {
  color: #ffffff;
  text-align: initial;
  font-size: 18px;
}
@media (min-width: 768px) {
  .order-list-item__metadata-date {
    text-align: center;
    font-size: 16px;
  }
}
.order-list-item__metadata-total {
  color: #92b851;
  font-weight: bold;
  text-align: initial;
  font-size: 18px;
}
@media (min-width: 768px) {
  .order-list-item__metadata-total {
    text-align: center;
    font-size: 16px;
  }
}
.order-list-item__actions {
  display: flex;
  gap: 10px;
  flex-direction: column-reverse;
  align-self: center;
  justify-items: center;
}
@media (min-width: 768px) {
  .order-list-item__actions {
    flex-direction: row;
  }
}
.order-list-item__actions-options-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-list-item__actions-options-btn.hidden {
  visibility: hidden;
}
.order-list-item__actions-map-btn {
  display: flex;
  justify-content: center;
  background: #8d3251;
  border: none;
  align-items: center;
}
.order-list-item__actions-map-btn:hover {
  background: #8d3251;
  filter: brightness(1.3);
}