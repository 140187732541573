.checkin-order-page {
  background-color: white;
  background-image: url("../../pics/TLAC-emailSignature-04.jpg");
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  max-height: 355px;
  margin-top: 40%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .checkin-order-page {
    margin-top: 0;
    max-height: none;
  }
}
.checkin-order-page__content {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}
.checkin-order-page__content-title {
  font-weight: bold;
}
@media (min-width: 768px) {
  .checkin-order-page__content-title {
    font-size: 25px;
  }
}
.checkin-order-page__content-msg {
  text-align: center;
  font-size: 14px;
}
@media (min-width: 768px) {
  .checkin-order-page__content-msg {
    font-size: 18px;
  }
}
.checkin-order-page__content-action {
  color: white;
  background-color: black;
  padding: 10px 15px;
  border-radius: 20px;
  text-transform: uppercase;
  cursor: pointer;
}
.checkin-order-page__content-action:hover {
  color: white;
  background-color: #8d3251;
}