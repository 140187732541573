@charset "UTF-8";
.suggest-recipe-page {
  padding: 10px 5%;
}
@media (min-width: 768px) {
  .suggest-recipe-page {
    padding: 10px 10vw;
  }
}
@media (min-width: 1024px) {
  .suggest-recipe-page {
    padding: 0 20vw;
  }
}
.suggest-recipe-page__form {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: url("https://firebasestorage.googleapis.com/v0/b/box2020-d5d80.appspot.com/o/app%2Fpurplepaint.jpg?alt=media");
  position: relative;
  z-index: 0;
  margin-bottom: 10px;
}
.suggest-recipe-page__form::after {
  content: "";
  height: 100%;
  width: 100%;
  -webkit-backdrop-filter: blur(10px) brightness(0.5) hue-rotate(1rad);
          backdrop-filter: blur(10px) brightness(0.5) hue-rotate(1rad);
  position: absolute;
  z-index: 1;
}
@media (min-width: 768px) {
  .suggest-recipe-page__form {
    height: -moz-fit-content;
    height: fit-content;
    overflow-y: scroll;
  }
}
.suggest-recipe-page__form #basic {
  padding: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin: 10px 10px 2.5px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .suggest-recipe-page__form #basic {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20vw, 1fr));
  }
}
.suggest-recipe-page__form #basic .form-input {
  display: flex;
  gap: 5px;
  justify-content: space-between;
}
.suggest-recipe-page__form #basic .form-input label {
  width: -moz-max-content;
  width: max-content;
}
.suggest-recipe-page__form #basic .form-input .ant-input {
  height: 30px;
}
.suggest-recipe-page__form #basic .form-input .ant-input-group-wrapper {
  width: 130px;
}
.suggest-recipe-page__form #basic .form-input .ant-input-group-wrapper .ant-input {
  text-align: center !important;
}
.suggest-recipe-page__form #basic .form-input .ant-input-group-addon {
  background: #8d3251;
  border: none;
  border-radius: 5px;
  padding: 0 5px;
}
.suggest-recipe-page__form #basic .form-input .ant-input-group-addon button {
  color: #ffffff;
  font-weight: bold;
}
.suggest-recipe-page__form #categories {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 0 15px 5px 15px !important;
  z-index: 2;
  margin: 2.5px 10px;
}
.suggest-recipe-page__form #categories .ant-spin-container {
  padding: 5px;
}
.suggest-recipe-page__form #categories .diet-item {
  background: #8d3251;
  filter: brightness(0.8);
  border-radius: 5px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.suggest-recipe-page__form #categories .diet-item.selected {
  filter: brightness(1.2);
  font-weight: bold;
  position: relative;
  cursor: pointer;
}
.suggest-recipe-page__form #categories .diet-item.selected::after {
  content: "✓";
  position: absolute;
  right: 5%;
  padding: 0 5px;
  background: #7c9c46;
  border-radius: 100%;
  font-size: 10px;
  top: 2px;
}
.suggest-recipe-page__form #categories .diet-item:hover {
  filter: brightness(1.2);
}
.suggest-recipe-page__form #ingredients {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin: 2.5px 10px;
}
.suggest-recipe-page__form #ingredients .ingredient-header {
  display: flex;
  justify-content: space-between;
}
.suggest-recipe-page__form #ingredients .ingredient-header__title {
  font-weight: bold;
}
.suggest-recipe-page__form #ingredients .ingredient-header__actions {
  display: flex;
  gap: 10px;
}
.suggest-recipe-page__form #ingredients .ingredient-header__actions .ant-btn {
  border-radius: 10px;
}
.suggest-recipe-page__form #ingredients .ingredient-header__actions .ant-btn:hover {
  color: #8d3251;
  border-color: #8d3251;
}
.suggest-recipe-page__form #ingredients .ingredient-header__actions .ant-btn-primary {
  background: #8d3251;
  border: none;
}
.suggest-recipe-page__form #ingredients .ingredient-header__actions .ant-btn-primary:hover {
  filter: brightness(1.2);
  color: #ffffff;
  border: none;
}
.suggest-recipe-page__form #ingredients .ant-list-items {
  height: 100px;
  overflow-y: scroll;
}
.suggest-recipe-page__form #ingredients .ant-list-items .ingredient-item {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}
.suggest-recipe-page__form #ingredients .ant-list-items .ingredient-item .form-input {
  display: flex;
  flex: 1;
  gap: 10px;
  font-size: 12px;
}
.suggest-recipe-page__form #ingredients .ant-list-items .ingredient-item .form-input input {
  font-size: 12px;
  padding: 0 1px;
}
.suggest-recipe-page__form #ingredients .ant-list-items .ingredient-item .form-input:last-child {
  flex: 2;
}
.suggest-recipe-page__form #steps {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin: 2.5px 10px;
}
.suggest-recipe-page__form #actions {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin: 2.5px 10px;
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .suggest-recipe-page__form #actions {
    justify-content: flex-end;
  }
}
.suggest-recipe-page__form #actions .suggest-button {
  background: #8d3251;
  border-radius: 10px;
  border: none;
}