@import '../../components/order/order-list/order-list.css';
.orders-page {
  padding: 0 5%;
}
@media (min-width: 768px) {
  .orders-page {
    padding: 0 10vw;
  }
}
@media (min-width: 1024px) {
  .orders-page {
    padding: 0 20vw;
  }
}