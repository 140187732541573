@import '../../components/recipe/card/card.css';
@import '../../components/banner/banner.css';
@import '../../components/recipe/accordion-list/accordion-list.css';
@import '../../components/recipe/shoppingcart-btn/floating-add-to-cart.css';
.pwa .pwa-back {
  display: none;
  position: absolute;
  left: 15px;
  top: 10px;
}

.site-header {
  max-width: 100%;
  position: relative;
}
.site-header .site-header-text {
  padding: 0 15%;
  line-height: 1;
  align-self: center;
  text-align: center;
}

.recipe-page {
  padding: 0 2%;
}
@media (min-width: 768px) {
  .recipe-page {
    padding: 0 10%;
  }
}
.recipe-page .recipe-info {
  display: flex;
  gap: 15px;
  border-radius: 20px;
  flex-direction: column;
  padding: 10px 10px 0 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
@media (min-width: 1024px) {
  .recipe-page .recipe-info {
    flex-direction: row;
    padding: 10px 0 0 10px;
  }
}
.recipe-page .recipe-info-basic {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
@media (min-width: 1024px) {
  .recipe-page .recipe-info-basic {
    width: 50%;
  }
}
.recipe-page .recipe-info-basic__description {
  padding: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
}
@media (min-width: 1024px) {
  .recipe-page .recipe-info-basic__description {
    font-size: 18px;
    text-align: justify;
  }
}
.recipe-page .recipe-info-advanced {
  width: 100%;
}
@media (min-width: 1024px) {
  .recipe-page .recipe-info-advanced {
    width: 50%;
    max-height: 350px;
  }
}
@media (max-width: 767px) {
  .recipe-page .recipe-info-advanced .accordion-list-item {
    max-height: 45px;
  }
  .recipe-page .recipe-info-advanced .accordion-list-item.selected {
    max-height: none;
  }
  .recipe-page .recipe-info-advanced .accordion-list-item-header {
    font-size: 16px;
  }
  .recipe-page .recipe-info-advanced .accordion-list-item-header .arrow-icon {
    height: 25px;
    width: 25px;
  }
}
.recipe-page .instagram-wall {
  margin: 10px auto 100px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .recipe-page .instagram-wall {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
  }
}
.recipe-page:empty {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.recipe-page:empty::after {
  content: "";
  width: 100%;
  height: 40vh;
  border-radius: 20px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
.recipe-page:empty::before {
  content: "";
  width: 100%;
  height: 100px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 14%);
  }
  100% {
    background-color: hsl(0, 0%, 20%);
  }
}