.floating-add-to-cart {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  padding: 10px;
  height: 65px;
}
@media (min-width: 768px) {
  .floating-add-to-cart {
    width: 240px;
    position: fixed;
    right: 40px;
    bottom: 40px;
    border-radius: 10px;
  }
}
.floating-add-to-cart-qty {
  flex: 1;
  border-radius: 10px;
  border: none;
  height: auto;
  display: flex;
  padding: 0 20px;
  font-size: 18px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, black 50%), linear-gradient(135deg, black 50%, transparent 50%), linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  cursor: pointer;
}
.floating-add-to-cart-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  gap: 5px;
  background: #8d3251;
  border-radius: 10px;
  height: auto;
  border: none;
}
.floating-add-to-cart-btn span {
  align-self: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
}
.floating-add-to-cart-btn:hover {
  background: #8d3251;
  filter: brightness(1.2);
}