.legal-page {
  padding: 25px 5vw;
  display: flex;
  flex-direction: column;
  background: #f0f2f5;
  text-align: justify;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .legal-page {
    padding: 10px 25%;
  }
}