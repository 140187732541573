.payment-page {
  padding: 0 5%;
}
@media (min-width: 768px) {
  .payment-page {
    padding: 0 10vw;
  }
}
@media (min-width: 1024px) {
  .payment-page {
    padding: 0 20vw;
  }
}