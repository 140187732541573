#map img {
  animation: none;
}

.map-container {
  position: relative;
}
.map-container .street-btn {
  position: absolute;
  right: 5px;
  bottom: 125px;
  width: -moz-fit-content;
  width: fit-content;
  height: 50px;
  background: #8d3251;
}
.map-container .street-btn img {
  filter: invert(100%);
}
.map-container .street-btn:hover, .map-container .street-btn:focus {
  background: #8d3251;
  filter: brightness(1.2);
  border-color: white;
}