.search-bar.recipes {
  margin: 0 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 0 0;
  border-radius: 10px;
}
.search-bar.recipes .search-bar-container {
  display: flex;
  height: 40px;
  width: 100%;
  align-self: center;
  gap: 10px;
}
@media (min-width: 768px) {
  .search-bar.recipes .search-bar-container {
    min-width: 36%;
    width: 36%;
    margin-top: -60px;
    margin-left: 64%;
  }
}
@media (min-width: 1024px) {
  .search-bar.recipes .search-bar-container {
    min-width: 285px;
    margin-left: calc(100% - 285px);
  }
}
.search-bar.recipes .search-bar-container-input {
  width: 100%;
  height: 40px;
  background-color: #EBEBEB;
  border-radius: 10px;
  border: none;
}
.search-bar.recipes .search-bar-container-input input {
  background: none;
}
.search-bar.recipes .search-bar-container-input input::-moz-placeholder {
  color: #707070;
}
.search-bar.recipes .search-bar-container-input input::placeholder {
  color: #707070;
}
.search-bar.recipes .search-bar-container-filter-btn {
  background-color: #E3E3E3;
  border-radius: 10px;
}
.search-bar.ingredients .ant-input-search {
  border-radius: 5px;
}