@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body {
  animation: fadeInAnimation ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: #262726 !important;
}
body.light {
  background: #f5f1f1 !important;
}
body.pwa .ant-layout-sider-zero-width-trigger {
  display: none;
}
body.pwa .pwa-logout {
  display: block;
  margin-left: -15px;
  cursor: pointer;
}
body.pwa .site-header {
  position: fixed;
  background-color: #262726;
  z-index: 100;
  width: 100%;
  display: none;
}
body.pwa .site-content {
  margin-top: 20px;
}
body.pwa .recipe .site-header {
  display: flex;
}
body.pwa .recipe .site-content {
  margin-top: 70px;
}
body .pwa-logout {
  display: none;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-card-body {
  padding: 5px !important;
}

.pwa-back {
  display: none;
}