.preference {
  height: 100px;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #8d3251;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
}
.preference-name {
  color: #ffffff;
}
.preference:hover, .preference:focus, .preference.selected {
  background: #8d3251;
  filter: brightness(1.2);
}