.site {
  background-color: #262726;
}
.site.light {
  background-color: #f5f1f1 !important;
}
.site.light .site, .site.light .react-modal-sheet-content, .site.light main {
  background-color: #f5f1f1 !important;
}
.site.light #sider {
  border-radius: 100px;
}
.site.light .card-list-list__item__title,
.site.light .story-frame-caption,
.site.light .recipe-info-basic__description,
.site.light .accordion-list-item-header,
.site.light .order-list-item__metadata-status,
.site.light .accordion-list-item-body__item-text,
.site.light .ant-breadcrumb-link,
.site.light .ant-breadcrumb-separator,
.site.light .order-list-item__metadata-date {
  color: #424040;
}
.site.light .card-list-list__item__title__counter,
.site.light .story-frame-caption__counter,
.site.light .recipe-info-basic__description__counter,
.site.light .accordion-list-item-header__counter,
.site.light .order-list-item__metadata-status__counter,
.site.light .accordion-list-item-body__item-text__counter,
.site.light .ant-breadcrumb-link__counter,
.site.light .ant-breadcrumb-separator__counter,
.site.light .order-list-item__metadata-date__counter {
  color: #ffffff;
}
.site.light .breadcrumb {
  margin-top: 10px;
}
.site.light .stories-list {
  border-top: 1px #c1c1c1 solid;
  border-bottom: 1px #c1c1c1 solid;
}
.site.light .search-bar {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .site.light .search-bar {
    margin-top: 0;
  }
}
.site #sider {
  background-color: #262726;
  box-shadow: 1px 1px 10px black;
  height: 100vh;
}
.site #sider .site-navbar-menu {
  background-color: #262726;
}
.site #sider .site-navbar-menu .ant-menu-item-selected {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.site-navbar {
  background-color: #262726;
}
.site-navbar-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  position: relative;
}
.site-navbar-header-picture {
  border-radius: 100%;
  align-self: center;
  z-index: 3;
  margin-top: 5px;
  filter: drop-shadow(0 0 1px #E3E3E3);
  height: 75px;
  width: 75px;
}
.site-navbar-header::after {
  content: "";
  height: 85px;
  width: 85px;
  background-color: rgba(141, 50, 81, 0.2509803922);
  position: absolute;
  z-index: 2;
  align-self: center;
  border-radius: 100%;
  margin-left: 5px;
}
.site-navbar-header::before {
  content: "";
  height: 85px;
  width: 85px;
  background-color: rgba(141, 50, 81, 0.2509803922);
  position: absolute;
  z-index: 1;
  align-self: center;
  border-radius: 100%;
  margin-right: 5px;
}
.site-navbar-header-content {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-size: 12px;
}
.site-navbar-header-content__user {
  font-size: 14px;
  text-align: center;
  font-weight: bold;
}
.site-header {
  background-color: #262726;
  display: flex;
  justify-content: center;
}
.site-header-text {
  font-size: 20px;
  color: #ffffff;
  text-shadow: 2px 2px 0 #ffffff80;
}
.site-header-logo {
  filter: drop-shadow(2px 2px 0 #ffffff80);
}
.site-content {
  background-color: #262726;
}
.site .ant-layout-sider-zero-width-trigger {
  background-color: #262726;
  right: -50px;
  top: 10px;
}
@media (min-width: 768px) {
  .site .ant-layout-sider-zero-width {
    width: 200px !important;
    min-width: 200px !important;
    max-width: 200px !important;
  }
  .site .ant-layout-sider-zero-width .ant-menu-inline-collapsed {
    width: 100%;
  }
  .site .ant-layout-sider-zero-width .ant-menu-inline-collapsed .ant-menu-item {
    padding-left: 24px !important;
  }
  .site .ant-layout-sider-zero-width .ant-menu-inline-collapsed .ant-menu-item span {
    opacity: 1;
    padding-left: 10px;
  }
}