@charset "UTF-8";
.ant-carousel {
  margin-top: -265px !important;
}

img {
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.confirm-logout .ant-modal-content {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: bold;
}
.confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn {
  border-radius: 5px;
}
.confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn:hover, .confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn:focus {
  border-color: #8d3251;
  color: #8d3251;
}
.confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #8d3251;
  border: none;
}
.confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover, .confirm-logout .ant-modal-content .ant-modal-confirm-btns .ant-btn.ant-btn-primary:focus {
  color: #ffffff;
  filter: brightness(1.2);
}

.ant-pagination-item {
  border-radius: 10px !important;
}
.ant-pagination-item-active {
  border-color: #8d3251 !important;
}
.ant-pagination-item-active :first-child {
  color: #8d3251;
}
.ant-pagination-item-active, .ant-pagination-item-link, .ant-pagination-item:hover {
  border-radius: 10px !important;
}
.ant-pagination-item-active:hover, .ant-pagination-item-link:hover, .ant-pagination-item:hover:hover {
  border-color: #8d3251 !important;
}
.ant-pagination-item-active:hover :first-child, .ant-pagination-item-active:hover :first-child:hover, .ant-pagination-item-link:hover :first-child, .ant-pagination-item-link:hover :first-child:hover, .ant-pagination-item:hover:hover :first-child, .ant-pagination-item:hover:hover :first-child:hover {
  color: #8d3251;
}

.breadcrumb {
  color: #707070;
  margin-bottom: 10px;
}
.breadcrumb :first-child .ant-breadcrumb-link {
  color: #707070;
}
.breadcrumb :last-child {
  color: #ffffff !important;
}

.pwa .breadcrumb {
  display: none;
}

.ant-switch-checked {
  background-color: #8d3251;
}

.ant-modal-confirm .ant-modal-content {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.ant-modal-confirm .ant-modal-content .ant-btn {
  border-radius: 10px;
}
.ant-modal-confirm .ant-modal-content .ant-btn:hover {
  color: #8d3251;
  border-color: #8d3251;
}
.ant-modal-confirm .ant-modal-content .ant-btn-primary {
  background: #8d3251;
  border: none;
  color: #ffffff;
}
.ant-modal-confirm .ant-modal-content .ant-btn-primary:hover {
  filter: brightness(1.2);
  color: #ffffff;
}

.ant-list-pagination {
  margin-top: 5px;
}

#content > div:not(.home-page) {
  max-width: 1800px;
}

.tutorial-selected {
  z-index: 10000 !important;
  position: relative;
}
.tutorial-selected::after {
  content: "⤶";
  color: white;
  text-shadow: 0 0 10px black;
  position: absolute;
  font-size: 50px;
  z-index: 10000 !important;
}
.tutorial-selected::before {
  content: attr(data-description);
  display: block;
  background: none;
  position: absolute;
  color: white;
  height: 50px;
  width: 200px;
  text-align: right;
  font-weight: 700;
  font-size: 16px;
}
.tutorial-selected.recipe-list::after {
  bottom: 0;
  left: 220px;
  transform: translateY(15px);
}
.tutorial-selected.recipe-list::before {
  bottom: 0;
  transform: translateY(25px);
  left: 0;
  width: 210px;
}
.tutorial-selected.recipe-list .recipe-card-item-footer__actions::after {
  right: 0;
  top: 0;
  transform: rotate(90deg) translate(-30px, 75px);
}
.tutorial-selected.recipe-list .recipe-card-item-footer__actions::before {
  transform: translate(0, -35px);
  top: 0;
  right: 0;
}
.tutorial-selected.search-bar-container::after {
  transform: translateY(15px);
  right: 0;
}
.tutorial-selected.search-bar-container::before {
  transform: translateY(50px);
  right: 50px;
}
.tutorial-selected.shoppingcart-btn::after {
  transform: translate(-35px, -30px) rotate(90deg);
  left: 0;
}
.tutorial-selected.shoppingcart-btn::before {
  transform: translate(-250px, -10px);
  left: 0;
  width: 60px;
  opacity: 1;
}

.tutorial {
  position: absolute;
  height: 100vh;
  width: 100vw;
  -webkit-backdrop-filter: brightness(0.15);
          backdrop-filter: brightness(0.15);
  top: 0;
  z-index: 1000;
}
.tutorial-nav {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 35px;
  display: flex;
  gap: 10px;
}
@media (min-width: 768px) {
  .tutorial-nav {
    bottom: 20px;
    right: 150px;
    top: unset;
  }
}
.tutorial-nav button {
  background: #8d3251;
  border: none;
  padding: 5px;
  color: #ffffff;
  border-radius: 5px;
  font-weight: 700;
  cursor: pointer;
}
.tutorial-nav button:hover {
  filter: brightness(1.2);
  color: #ffffff;
}

.fade-in {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2s ease-in-out, transform 2s ease-in-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}