.order-details-modal__title {
  font-weight: bold;
}
.order-details-modal .ant-modal-body {
  padding: 15px;
}
.order-details-modal .ant-modal-content {
  border-radius: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.order-details-modal .ant-modal-content .ant-modal-confirm-content {
  margin-left: 0 !important;
  margin-top: 20px;
}
.order-details-modal .ant-modal-confirm-btns .ant-btn {
  border-radius: 10px;
  border: none;
}
.order-details-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #8d3251;
}
.order-details-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover {
  filter: brightness(1.2);
  color: #ffffff;
}
.order-details-modal .order-details__date {
  display: flex;
  justify-content: space-between;
}
.order-details-modal .order-details__date :first-child {
  font-weight: bold;
}
.order-details-modal .order-details__date :last-child {
  margin-bottom: 10px;
}
.order-details-modal .order-details-list-item {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding-left: 10px;
}
.order-details-modal .order-details-list-item:first-child {
  border-radius: 10px 10px 0 0;
}
.order-details-modal .order-details-list-item:last-child {
  border-radius: 0 0 10px 10px;
}
.order-details-modal .order-details-list-item__total {
  font-size: 18px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #92b85180;
  color: #7c9c46;
}
.order-details-modal .order-details-footer {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.order-details-modal .order-details-footer__total {
  font-size: 20px;
  font-weight: bold;
  text-shadow: 1px 1px 0 #92b85180;
  color: #7c9c46;
}