@import '../order-list-item/order-list-item.css';
@import '../order-filter/order-filter.css';
@import '../order-address/order-address.css';
@import '../order-details/order-details.css';
.order-list .ant-empty-image {
  height: -moz-fit-content;
  height: fit-content;
}
.order-list .ant-spin-container {
  padding-top: 10px;
}