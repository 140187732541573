@import '../card/card-item.css';
.recipe-list {
  position: relative;
  display: flex;
  animation: fadeInAnimation ease 2.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.recipe-list-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 20px;
}
.recipe-list-container:last-child {
  margin-right: 20px;
}
.recipe-list-btns {
  position: absolute;
  align-self: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 0;
}
.recipe-list-btns__btn {
  font-size: 18px;
  border: none;
  font-weight: 900;
  padding: 5px 8px;
  height: 50px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5294117647);
  color: white;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}
.recipe-list-btns__btn.next {
  border-radius: 20px 0 0 20px;
  box-shadow: -1px 0 10px rgb(34, 34, 34);
}
.recipe-list-btns__btn.prev {
  border-radius: 0 20px 20px 0;
  box-shadow: 1px 0 10px rgb(34, 34, 34);
}