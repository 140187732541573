.banner {
  display: none;
}
@media (min-width: 768px) {
  .banner {
    display: block;
    height: 100px;
    width: 100%;
    background-color: black;
    margin-bottom: 20px;
  }
}