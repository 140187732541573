.recipe-card {
  width: 100%;
  min-width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s;
  z-index: 1;
  position: relative;
}
.recipe-card-img {
  z-index: 2;
  min-height: 215px;
  cursor: pointer;
}
.recipe-card-img img {
  border-radius: 20px;
}
.recipe-card-header {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 20px 20px 0 0;
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 15px;
}
.recipe-card-header__row1 {
  display: flex;
  justify-content: space-between;
}
.recipe-card-header__row1__diets {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0 10px 10px 0;
  display: flex;
  gap: 5px;
}
.recipe-card-header__row1__likes {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 80px;
  margin-right: 15px;
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
  border: none;
}
.recipe-card-header__row1__likes:hover, .recipe-card-header__row1__likes:focus {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  filter: brightness(1.2);
  color: #ffffff;
}
@keyframes beat {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.recipe-card-header__row1__likes.liked img {
  animation: beat 0.3s normal forwards ease-in-out;
}
.recipe-card-header__row2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 20px;
}
@media (min-width: 768px) {
  .recipe-card-header__row2 {
    padding: 10px 30px;
  }
}
.recipe-card-header__row2__recipe-metadata {
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  gap: 10px;
}
.recipe-card-header__row2__recipe-metadata span {
  align-self: center;
}
.recipe-card-footer {
  z-index: 3;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 0 0 20px 20px;
}
.recipe-card-footer__actions {
  display: flex;
  margin-bottom: 15px;
  gap: 10px;
}
.recipe-card-footer__actions__price-selector {
  border-radius: 5px 5px 5px 35px;
  display: flex;
  align-content: center;
  padding: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.recipe-card-footer__actions__price-selector__btn {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: none;
  border: none;
  color: #000000;
  padding: 0 5px;
  cursor: pointer;
  display: flex;
  gap: 5px;
}
.recipe-card-footer__actions__price-selector__btn.selected {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
  color: #ffffff;
  cursor: default;
}
.recipe-card-footer__actions__price-selector__btn.selected:hover {
  color: #ffffff;
}
.recipe-card-footer__actions__price-selector__btn:hover {
  color: #000000;
}