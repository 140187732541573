.main-page {
  background: url(../../pics/BACKGROUND.jpg) !important;
  background-repeat: repeat !important;
  background-size: 1150px !important;
}
.main-page .header {
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  box-shadow: none;
  padding: 22px;
  position: fixed;
  width: 100%;
}
@media (min-width: 768px) {
  .main-page .header {
    justify-content: space-around;
  }
}
.main-page .header-logo {
  align-self: center;
}
.main-page .pwa-signin {
  display: none;
}
.main-page .navbar {
  display: none;
  gap: 10px;
  flex: 2;
  justify-content: flex-end;
  margin-right: 50px;
}
@media (min-width: 768px) {
  .main-page .navbar {
    display: flex;
  }
}
.main-page .navbar-item {
  font-size: 14px;
  background: transparent;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.5s;
  align-self: center;
  color: black;
}
.main-page .navbar-item:hover {
  background-color: #8d3251;
  color: #ffffff;
  border-radius: 5px;
}
.main-page .signin {
  background: black;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .main-page .signin {
    font-size: 18px;
    padding: 10px 20px;
  }
}
.main-page .signin:hover {
  background-color: #8d3251;
}

.content {
  height: 100vh;
}
.content form {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.content form .signin-btn {
  background: #8d3251;
  width: 100%;
  max-width: 272px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}
.content form .signin-btn:hover, .content form .signin-btn:focus {
  background: #8d3251;
  filter: brightness(1.2);
}
.content form .signin-btn-google {
  max-width: 272px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}
.content form .signup-btn {
  width: 100%;
  max-width: 272px;
}
.content form .signup-btn:hover {
  border-color: #8d3251;
  color: #8d3251;
}
.content form .forgot-password {
  opacity: 0.8;
  align-self: center;
  cursor: pointer;
}
.content form span {
  align-self: center;
}
.content form .error-message {
  color: rgb(124, 0, 0);
  align-self: center;
}

.ant-layout-footer {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -133px;
  left: 0;
  justify-content: space-between;
  background: #262726 !important;
  border-top: 1px rgba(255, 255, 255, 0.108) solid;
  color: #ffffff !important;
}
@media (max-width: 767px) {
  .ant-layout-footer {
    flex-direction: column;
    align-items: center;
  }
}
.ant-layout-footer a {
  color: #ffffff;
}
.ant-layout-footer .social-footer {
  display: flex;
  gap: 10px;
}