@import '../card-list/card-list.css';
.card-list-list {
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 15px;
}
.card-list-list__item {
  display: flex;
  flex-direction: column;
}
.card-list-list__item__title {
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
  font-size: 20px;
  text-transform: capitalize;
}
.card-list-list__item__title__counter {
  text-shadow: none;
  background-color: #8d3251;
  font-size: 18px;
  margin-left: 15px;
  padding: 2px 10px;
  border-radius: 100%;
}
.card-list-list:empty {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.card-list-list:empty::after {
  content: "";
  width: 100%;
  height: 280px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
.card-list-list:empty::before {
  content: "";
  width: 100%;
  height: 280px;
  background-color: black;
  animation: skeleton-loading 0.7s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 14%);
  }
  100% {
    background-color: hsl(0, 0%, 20%);
  }
}