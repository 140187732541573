.order-filter {
  display: flex;
  gap: 10px;
  justify-content: center;
}
@media (min-width: 768px) {
  .order-filter {
    justify-content: flex-start;
  }
}
.order-filter .ant-select, .order-filter .ant-select-selector {
  border-radius: 5px !important;
  font-size: 20px;
}
@media (min-width: 768px) {
  .order-filter .ant-select, .order-filter .ant-select-selector {
    font-size: 16px !important;
  }
}