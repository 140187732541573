@import '../../components/recipe/search-bar/search-bar.css';
@import '../../components/recipe/card-list-list/card-list-list.css';
@import '../../components/recipe/story-list/story-list.css';
@import '../../components/recipe/preferences-modal/preferences-modal.css';
.recipe-selector .ant-modal {
  border-radius: 10px;
}
.recipe-selector .ant-modal-content {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.recipe-selector .ant-modal-header {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  border-radius: 10px 10px 0 0;
}
.recipe-selector .ant-modal-close-icon {
  filter: drop-shadow(1px 0 0 #fff);
}
.recipe-selector .ant-modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.recipe-selector-title {
  color: #222222;
  width: 100%;
  font-weight: bold;
}
.recipe-selector-cancel-btn {
  border: none;
  border-radius: 10px;
}
.recipe-selector-cancel-btn:hover {
  color: #8d3251;
}
.recipe-selector-confirm-btn {
  background: #8d3251;
  color: #ffffff;
  font-weight: bold;
  border: none;
  border-radius: 10px;
}
.recipe-selector-confirm-btn:hover {
  color: #ffffff;
  background: #8d3251;
  filter: brightness(1.2);
}
.recipe-selector-list-search-bar-filters-diet.ant-tag-checkable-checked {
  background-color: #8d3251;
}
.recipe-selector-list .ant-spin-container {
  max-height: 350px;
  overflow-y: scroll;
  padding-top: 5px;
}
@media (min-width: 768px) {
  .recipe-selector-list .ant-spin-container {
    max-height: 425px;
  }
}
.recipe-selector-list-item-card {
  max-height: 350px;
}
.recipe-selector-list-item-card-check .ant-checkbox-inner {
  background-color: #8d3251;
  border: #8d3251;
  width: 25px;
  height: 25px;
  border-radius: 100%;
}
.recipe-selector-list-item-card-check .ant-checkbox-inner::after {
  margin-left: 4px;
}
.recipe-selector-list-item-card-cover {
  min-height: 100px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
  text-align: center;
}
.recipe-selector-list-item-card .ant-card-cover img {
  border-radius: 10px 10px 0 0;
}
.recipe-selector-list-item-card .ant-card-actions {
  border-radius: 0 0 10px 10px;
}
.recipe-selector-list-item-card .ant-card-meta-description {
  height: 50px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.recipe-selector-list-item-card .ant-card-meta-title {
  font-weight: bold;
  min-height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: unset;
  text-align: center;
}

.confirm-order-continue {
  max-height: 40px;
  display: flex;
  justify-content: center;
  flex: 1;
}
.confirm-order-continue-btn {
  background-color: #7c9c46;
  border-color: #7c9c46;
  font-weight: bold;
}
.confirm-order-continue-btn:hover {
  background-color: #92b851;
}
@media (min-width: 1024px) {
  .confirm-order-continue {
    flex: 1;
  }
}
.confirm-order-address {
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.confirm-order-address-select {
  width: calc(100% - 32px);
}
@media (min-width: 1024px) {
  .confirm-order-address {
    flex: 1;
  }
}
.confirm-order-hour {
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.confirm-order-hour-item.ant-btn-primary {
  background-color: #8d3251;
  color: #fff;
}
.confirm-order-hour-container {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
}
@media (min-width: 1024px) {
  .confirm-order-hour {
    flex: 1;
  }
}
.confirm-order .ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (min-width: 1024px) {
  .confirm-order .ant-drawer-body {
    flex-direction: row;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.ant-drawer-content-wrapper {
  height: 380px !important;
}
@media (min-width: 1024px) {
  .ant-drawer-content-wrapper {
    height: 260px !important;
  }
}

.order-pack .ant-drawer-content-wrapper {
  height: 85vh !important;
}

.home-page .week-planning-days-item-card-list-empty-item {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #8d325150;
  min-height: 60;
  align-items: center;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
}
.home-page .week-planning-days-item-card-list-empty-item:hover {
  background: #8d3251;
}
.home-page .week-planning-days-item-card-list-empty-item.snack {
  background: rgba(143, 118, 128, 0.5490196078);
}
.home-page .week-planning-days-item-card-list-empty-item.snack:hover {
  background: #8f556c;
}
.home-page .week-planning-confirm {
  background-color: #7c9c46;
  border-color: #7c9c46;
  font-weight: bold;
}
.home-page .week-planning-confirm:hover {
  background-color: #92b851;
}
.home-page .week-planning-is-cooked .ant-radio-button-wrapper:first-child {
  border-radius: 5px 0 0 5px;
}
.home-page .week-planning-is-cooked .ant-radio-button-wrapper:last-child {
  border-radius: 0 5px 5px 0;
}
.home-page .week-planning-is-cooked .ant-radio-button-wrapper-checked {
  background: #8d3251 !important;
  border-color: #8d3251 !important;
}
.home-page .week-planning-is-cooked .ant-radio-button-wrapper-checked:hover {
  background: #8d325190;
}
.home-page .week-planning .ant-card-extra {
  padding: 8px 0;
}
.home-page .ant-card {
  border-radius: 10px;
}
.home-page .ant-card-head {
  border-radius: 10px 10px 0 0;
}
.home-page .ant-card .ant-list-item {
  padding: 5px 0;
}