.signup-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: 100%;
}
.signup-page-content .signup-form {
  display: flex;
  flex-direction: column;
}
.signup-page-content .signup-form button {
  align-self: flex-end;
  background: #8d3251;
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  color: white;
}
.signup-page-content .signup-form button:hover, .signup-page-content .signup-form button:focus {
  background: #8d3251;
  filter: brightness(1.2);
}