.recipe-card-item {
  width: 280px;
  min-width: 280px;
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  margin: 20px 0;
  transition: background-image 3s;
  box-shadow: 5px 5px 10px rgb(32, 32, 32);
}
@media (min-width: 768px) {
  .recipe-card-item {
    width: 330px;
    min-width: 330px;
    height: 320px;
  }
}
.recipe-card-item:hover {
  box-shadow: 0 0 10px white;
  height: 265px;
  width: 285px;
}
@media (min-width: 768px) {
  .recipe-card-item:hover {
    height: 330px;
    width: 330px;
  }
}
.recipe-card-item-header {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 20px 20px 0 0;
}
.recipe-card-item-header__row {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.recipe-card-item-header__row__title {
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
}
@media (min-width: 768px) {
  .recipe-card-item-header__row__title {
    font-size: 20px;
  }
}
.recipe-card-item-header__row__likes {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  gap: 5px;
  width: 80px;
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
}
.recipe-card-item-header__row__likes:hover {
  color: #ffffff;
  text-shadow: 1px 1px 0 #ffffff80;
}
.recipe-card-item-header__diets {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 0 10px 10px 0;
  display: flex;
  gap: 5px;
}
.recipe-card-item-footer {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 0 0 20px 20px;
}
.recipe-card-item-footer__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.recipe-card-item-footer__actions__price-selector {
  margin-bottom: 20px;
  border-radius: 5px 5px 5px 35px;
  display: flex;
  align-content: center;
  padding: 10px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
}
.recipe-card-item-footer__actions__price-selector__btn {
  border-radius: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background: none;
  border: none;
  color: #000000;
  padding: 0 5px;
  cursor: pointer;
}
.recipe-card-item-footer__actions__price-selector__btn.selected {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
  color: #ffffff;
  cursor: default;
}
.recipe-card-item-footer__actions__price-selector__btn.selected:hover {
  color: #ffffff;
}
.recipe-card-item-footer__actions__price-selector__btn:hover {
  color: #000000;
}
.recipe-card-item-footer__actions__add-btn {
  align-self: flex-end;
  margin-right: 20px;
  background-color: #7c9c46;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 8px white;
}
.recipe-card-item-footer__actions__add-btn:hover {
  background-color: #92b851;
  box-shadow: 0 0 10px white;
}
.recipe-card-item-footer__difficulty {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  margin-left: 10px;
  padding: 5px 20px;
  border-radius: 80%;
  height: -moz-fit-content;
  height: fit-content;
  align-self: flex-end;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}