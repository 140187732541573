.address-info-list-item {
  display: flex !important;
  justify-content: space-between;
  border-bottom: 1px solid rgb(219, 219, 219) !important;
  padding-bottom: 10px !important;
}
.address-info-list-item__data {
  flex: 10;
  display: flex;
  flex-direction: column;
}
.address-info-list-item__data-address {
  font-weight: bold;
}
.address-info-list-item__data-reference {
  font-size: 11px;
}
.address-info-list-item__actions {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .address-info-list-item__actions {
    flex-direction: row;
  }
}
.address-info-list-item__actions .ant-switch, .address-info-list-item__actions .ant-btn {
  align-self: center;
}
.address-info-list-item__actions .options-btn.hidden {
  visibility: hidden;
}