@import '../../components/user/user-info/user-info.css';
@import '../../components/user/user-health/user-health.css';
@import '../../components/user/user-address/user-address.css';
.user-profile-page {
  padding: 0 5%;
  width: 100vw;
  overflow-x: scroll;
}
@media (min-width: 768px) {
  .user-profile-page {
    padding: 0 10vw;
    width: 100%;
  }
}
.user-profile-page img {
  animation: none;
}
.user-profile-page .ant-card-head {
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0;
  color: white;
}

.user-profile-card {
  border-radius: 20px;
  min-height: 500px;
}
.user-profile-card__cover {
  position: relative;
  border-radius: 20px;
}
.user-profile-card__cover .profile-pic-container {
  min-height: 500px;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-backdrop-filter: blur(10px) brightness(0.5) hue-rotate(1rad);
          backdrop-filter: blur(10px) brightness(0.5) hue-rotate(1rad);
}
.user-profile-card__cover__profile-pic {
  border-radius: 100%;
  position: relative;
}
.user-profile-card__cover__profile-pic img {
  border-radius: 100%;
  height: 100px;
  width: 100px;
  z-index: 2;
  position: inherit;
  margin-bottom: 10px;
}
.user-profile-card__cover__profile-pic::after {
  content: "";
  z-index: 1;
  position: absolute;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  height: 110px;
  width: 110px;
  border-radius: 100%;
  transform: translate(-108px, -5px);
}
.user-profile-card__cover__profile-pic::before {
  content: "";
  z-index: 0;
  position: absolute;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%) !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
  height: 110px;
  width: 110px;
  border-radius: 100%;
  transform: translate(-2px, -5px);
}
.user-profile-card__cover__name {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 10px;
}
.user-profile-card__cover__upload {
  position: absolute;
  right: 0;
  top: 20px;
}
.user-profile-card .profile-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}